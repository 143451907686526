import React, { ReactNode, CSSProperties, HTMLAttributes } from "react";

export type BoxProps = {
  /**
   * The content of the box.
   */
  children?: ReactNode;
  /**
   * The CSS properties of the box.
   */
  css?: CSSProperties;
} & HTMLAttributes<HTMLDivElement>;

export const Box = ({ css, children, ...divProps }: BoxProps) => {
  return (
    <div style={css} {...divProps}>
      {children}
    </div>
  );
};
