/**
 * TODO Wrong component. Switch back to Popover with a custom hover handler.
 * https://github.com/radix-ui/primitives/issues/1077#issuecomment-1068306262
 */

/* eslint-disable react/display-name */
import React, { useRef } from "react";
import * as HoverCardPrimitive from "@radix-ui/react-hover-card";

import { Popover } from "@puzzle/ui";
import { styled } from "@puzzle/theme";

import { IS_CLIENT } from "lib/config";
import { MODAL_ROOT_ID } from "constants/ui";

const styles = Popover.styles;

const Arrow = styled(HoverCardPrimitive.Arrow, styles.Arrow);
const Content = styled(HoverCardPrimitive.Content, styles.Content, {
  transformOrigin: "var(--radix-hover-card-content-transform-origin)",
});
const Title = styled("div", styles.Title, {
  defaultVariants: {
    variant: "minimal",
  },
});
const Body = styled("div", styles.Body);

const HoverCard = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof HoverCardPrimitive.Root> &
    React.ComponentProps<typeof HoverCardPrimitive.Content> & {
      trigger?: React.ReactNode;
      anchor?: React.ReactNode;
      arrow?: boolean;
      width?: number;
    }
>(
  (
    {
      defaultOpen,
      open,
      onOpenChange,
      openDelay = 100,
      closeDelay = 300,
      children,
      trigger,
      anchor,
      width,
      arrow = false,
      ...props
    },
    ref
  ) => {
    const triggerRef = useRef<HTMLAnchorElement | null>(null);
    const modalRoot = IS_CLIENT ? document.getElementById(MODAL_ROOT_ID) : null;

    return (
      <HoverCardPrimitive.Root
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
        openDelay={openDelay}
        closeDelay={closeDelay}
      >
        {trigger && (
          <HoverCardPrimitive.Trigger ref={triggerRef} asChild>
            {trigger}
          </HoverCardPrimitive.Trigger>
        )}

        <HoverCardPrimitive.Portal container={modalRoot}>
          <Content
            // NOTE: Keep these props in sync with Popover
            sideOffset={8}
            // This fixes top-start/top-end so the arrow is aligned with the trigger
            alignOffset={arrow ? -20 : 0}
            collisionPadding={8}
            avoidCollisions
            onInteractOutside={(event) => {
              if (event.target && triggerRef.current?.contains(event.target as Node)) {
                event.preventDefault();
              }
            }}
            {...props}
            ref={ref}
            css={{ width }}
          >
            {children}
            {arrow && <Arrow offset={24} width={11} height={5} />}
          </Content>
        </HoverCardPrimitive.Portal>
      </HoverCardPrimitive.Root>
    );
  }
);

const Trigger = HoverCardPrimitive.Trigger;

export default Object.assign(HoverCard, { Title, Body, Trigger });
