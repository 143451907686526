import React from "react";
import { progressBarStyle, progressBarStatusStyle } from "./progressBar.css";

type ProgressBarProps = {
  progress: number;
};

export const TEST_IDS = {
  PROGRESS_BAR_CONTAINER: "progress-bar-container",
  PROGRESS_BAR_STATUS: "progress-bar-status",
};

export const ProgressBar = ({ progress }: ProgressBarProps) => {
  const validProgress = Math.min(Math.max(progress, 0), 100);
  return (
    <div data-testid={TEST_IDS.PROGRESS_BAR_CONTAINER} className={progressBarStyle}>
      <div
        style={{ "--progress-width": `${validProgress}%` } as React.CSSProperties}
        data-testid={TEST_IDS.PROGRESS_BAR_STATUS}
        className={progressBarStatusStyle}
      />
    </div>
  );
};
