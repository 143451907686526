/* eslint-disable react/display-name */
import React from "react";

import {
  contentRecipe,
  addonRecipe,
  textStyle,
  buttonRecipe,
  ButtonVariants,
} from "./buttonStyles.css";
import { Loader } from "../Loader/Loader";

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement> &
    ButtonVariants & {
      fullWidthContent?: boolean;
      children?: React.ReactNode;
      css?: React.CSSProperties;
      disabled?: boolean;
      prefixElement?: React.ReactElement;
      suffixElement?: React.ReactElement;
    }
>(
  (
    {
      children,
      css,
      prefixElement,
      suffixElement,
      fullWidth,
      fullWidthContent,
      variant,
      size,
      shape,
      loading,
      wrapText,
      disabled,
      ...props
    },
    ref
  ) => {
    const buttonClass = buttonRecipe({ fullWidth, variant, size, shape, loading, wrapText });
    const showSpinner = loading && !disabled;
    const contentClass = contentRecipe({ hide: showSpinner, fullWidth: fullWidthContent });
    const spinnerVariant =
      typeof variant === "undefined" || variant === "primary"
        ? "dark"
        : variant === "negative"
          ? "light"
          : "primary";

    return (
      <button className={buttonClass} style={css} {...props} ref={ref} aria-disabled={disabled}>
        <div className={contentClass}>
          {prefixElement && <div className={addonRecipe({ side: "left" })}>{prefixElement}</div>}
          <div className={textStyle}>{children}</div>
          {suffixElement && <div className={addonRecipe({ side: "right" })}>{suffixElement}</div>}
        </div>
        {showSpinner && <Loader css={{ position: "absolute" }} variant={spinnerVariant} />}
      </button>
    );
  }
);
