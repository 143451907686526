import React from "react";
import RSelect, { GroupBase } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { SelectComponents } from "react-select/dist/declarations/src/components";

import { ChevronDown, Close } from "@puzzle/icons";
// TODO: fix this import
import { Feedback } from "@puzzle/ui/src/lib/form/types";
import { useFieldContext } from "@puzzle/ui";

import {
  cleraIndicatorContainerStyle,
  divTestIdStyle,
  inputStyle,
  optionStyle,
  selectControlStyle,
  selectIndicatorContainerStyle,
  selectMenuStyle,
  selectSingleValueStyle,
  selectValueControlStyle,
} from "./selectStyles.css";
import { SelectOption } from "./types";
import { Box } from "@puzzle/ve";
import { Loader } from "../Loader/Loader";
import { vars } from "@puzzle/theme";

type SelectProps = {
  options: SelectOption[];
  value?: SelectOption | undefined;
  onChange?: (newValue: SelectOption) => void;
  placeholder?: string;
  dataTestId?: string;
  className?: string;
  inputValue?: string;
  onInputChange?: (newValue: string) => void;
  loading?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  filterOption?: ((option: FilterOptionOption<SelectOption>, inputValue: string) => boolean) | null;
  components?: Partial<SelectComponents<SelectOption, false, GroupBase<SelectOption>>>;
  noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode;
  feedback?: Feedback;
  backgroundColor?: "default" | "transparent";
  inputId?: string;
  instanceId?: number | string;
  narrow?: boolean;
  height?: "small" | "medium";
};

export const Select = ({
  options,
  dataTestId,
  value,
  className,
  onChange,
  placeholder,
  inputValue,
  onInputChange,
  loading,
  searchable,
  clearable,
  filterOption,
  components,
  noOptionsMessage,
  feedback,
  backgroundColor = "default",
  inputId,
  instanceId,
  narrow,
  height = "small",
}: SelectProps) => {
  const fieldContext = useFieldContext();
  return (
    <div className={divTestIdStyle()} data-testid={dataTestId}>
      <RSelect
        inputId={inputId}
        instanceId={instanceId}
        filterOption={filterOption}
        inputValue={inputValue}
        isClearable={clearable}
        isSearchable={searchable}
        className={className}
        placeholder={placeholder}
        isLoading={loading}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        onChange={(newValue) => onChange && newValue && onChange(newValue)}
        classNames={{
          control: (props) =>
            selectControlStyle({
              height,
              backgroundColor,
              feedback: fieldContext.feedback || feedback,
              variant: props.isFocused ? "focused" : undefined,
            }),
          indicatorsContainer: () => selectIndicatorContainerStyle(),
          valueContainer: () => selectValueControlStyle({ narrow }),
          singleValue: () => selectSingleValueStyle(),
          menu: () => selectMenuStyle(),
          option: (props) =>
            optionStyle({
              variant: props.isSelected ? "active" : props.isFocused ? "focused" : undefined,
            }),
          input: () => inputStyle(),
        }}
        styles={{
          control: () => ({}),
          indicatorSeparator: () => ({}),
          indicatorsContainer: () => ({}),
          singleValue: (styles) => ({
            ...styles,
            color: undefined,
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: undefined,
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: undefined,
          }),
          option: () => ({}),
          input: (styles) => ({
            ...styles,
            color: undefined,
          }),
        }}
        components={{
          LoadingIndicator: () => (
            <Box css={{ padding: `0 ${vars.space["0h"]}` }}>
              <Loader css={{ position: "relative" }} size={14} />
            </Box>
          ),
          DropdownIndicator: () => <ChevronDown />,
          ClearIndicator: ({ innerProps: { ref, ...restInnerProps } }) => (
            <div className={cleraIndicatorContainerStyle()} {...restInnerProps} ref={ref}>
              <Close size={14} />
            </div>
          ),
          ...components,
        }}
        value={value}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label ?? option.value}
        options={options}
      />
    </div>
  );
};
